export const lazy = (app) => {
  app.directive('lazy', {
    mounted(el, binding) {
      const imgObserver = new IntersectionObserver((entries) => {
        const lazyImg = entries[0].target;

        if (entries[0].isIntersecting) {
          lazyImg.src = binding.value;
        }
      });

      imgObserver.observe(el);
    },
    beforeUpdate(el, binding) {
      // 在传入的图片 URL 发生变化时重新赋值
      if (binding.oldValue !== binding.value) {
        el.src = binding.value;
      }
    }
  });
};
