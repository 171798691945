import request from './http';
import ferrousMetallurgy from './ferrousMetallurgy/index';
import major from './major/index';
import person from './person';
import home from './home/index.js';
const api = {
  ...ferrousMetallurgy, // 钢铁冶金
  ...major, //欧盟基金
  ...person, //个人中心
  ...home, // 首页
  //用户登录接口
  userLogin(data) {
    let params = {
      method: 'post',
      url: `/auth/login`,
      data: data
    };
    return request(params);
  },
  userLoginOut: {
    method: 'post',
    url: '/login/login/out'
  },
  // 获取登录者信息
  getUserInfo(data = {}, config) {
    let params = {
      method: 'get',
      url: `/system/user/getInfo`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取菜单
  getMenuList(data) {
    let params = {
      method: 'get',
      url: `/system/menu/getRouters`,
      data: data
    };
    return request(params);
  },
  uploadFile: {
    method: 'post',
    url: '/file/upload'
  },
  // 获取阿里云凭证
  getOssSign: {
    method: 'get',
    url: '/file/oss/wechat/get/sign'
  },
  // 保存阿里云上传成功后的附件到数据库
  saveOss: {
    method: 'post',
    url: '/file/oss/upload'
  },
  // 根据ID获取临时下载地址
  getOssById: {
    method: 'get',
    url: '/file/oss/byId'
  }
};
export default api;
