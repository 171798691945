// 开发环境
export let clientUrl = '';
export let fileUrl = '';
export let systemUrl = '';
export let UPLOADFILEURL = '';
export let BASEURL = '';
export let IMGUPLOADURL = '';
export let SHAREURL = '';
export let downLoadFileUrl = '';
export let opinionFILEURL = '';

if (process.env.NODE_ENV === 'development') {
  clientUrl = '';
  fileUrl = '';
  systemUrl = '/system';
  UPLOADFILEURL = 'http://192.168.0.107:8080/file/down/by/id';
  opinionFILEURL = 'http://192.168.0.107:8080/file/upload';
  BASEURL = '';
  downLoadFileUrl = '';
  IMGUPLOADURL = 'http://192.168.0.107:8080/file/upload/head';
  SHAREURL = 'http://localhost:8080/#';
} else {
  // 本地打包测试
  // clientUrl = '/client';
  // fileUrl = '/file';
  // systemUrl = '/system';
  // UPLOADFILEURL = 'http://10.10.10.206:8080/file/down/by/id';
  // opinionFILEURL = 'http://10.10.10.206:8080/file/upload';
  // BASEURL = 'http://10.10.10.206:8080';
  // downLoadFileUrl = 'http://10.10.10.206:8080/client';
  // IMGUPLOADURL = 'http://10.10.10.206:8080/file/upload/head';
  // SHAREURL = 'http://localhost:8080/#';

  clientUrl = '/client';
  fileUrl = '/file';
  systemUrl = '/system';
  UPLOADFILEURL = 'https://sciplatform-us.steel.org.cn/api/file/down/by/id';
  opinionFILEURL = 'https://sciplatform-us.steel.org.cn/api/file/upload';
  BASEURL = 'https://sciplatform-us.steel.org.cn/api';
  downLoadFileUrl = 'https://sciplatform-us.steel.org.cn/api';
  IMGUPLOADURL = 'https://sciplatform-us.steel.org.cn/api/file/upload/head';
  SHAREURL = 'https://sciplatform-us.steel.org.cn/#';
}
