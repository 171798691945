import { request } from '../http';
import { clientUrl } from '../baseURL';
const api = {
  // -------------------------------研究报告
  // 查询热词列表
  getReportHotSearch(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/reportWord/list`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 新增热词列表
  addReportHotSearch(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/reportWord/add`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取来源类型列表
  getAllTypeInReport(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/reprot/typeList`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取报告来源导航列表
  getAllSourceInReport(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/reprot/sourceList`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取时间
  getAllTimeInReport(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/reprot/releaseTimeList`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取技术领域
  getAllTechnologyNavigationList(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/reprot/technologyNavigationList`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取研究报告顶部数量统计
  getReportTopCount(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/report/getReportTopCount`,
      data: data,
      config: config
    };
    return request(params);
  },
  /**
   * @interface /report/reportPageList
   * @methods post
   * @desc 研究报告列表接口
   * @param pageSize、pageNum
   */
  getReportPageList(data) {
    let params = {
      method: 'post',
      url: `${clientUrl}/report/reportPageList?pageNum=${data.pageNum}&pageSize=${data.pageSize}`,
      data: data
    };
    return request(params);
  },
  /**
   * @interface /report/getInfo
   * @methods get
   * @desc 研究报告详情
   * @param id
   */
  getReportDetailInfo(data) {
    let params = {
      method: 'get',
      url: `${clientUrl}/report/getInfo/${data.id}`
    };
    return request(params);
  },
  /**
   * @interface /report/getInfo
   * @methods post
   * @desc 研究报告pdf权限申请
   * @param data
   */
  getReportFilePermission(data) {
    let params = {
      method: 'post',
      url: `${clientUrl}/review/add`,
      data
    };
    return request(params);
  },
  /**
   * @interface /report/updateVisitsCount
   * @methods get
   * @desc 统计研究报告查看次数
   * @param id
   */
  updateReportVisitsCount(data) {
    let params = {
      method: 'get',
      url: `${clientUrl}/report/updateVisitsCount`,
      data
    };
    return request(params);
  }
};
export default api;
