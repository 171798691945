export const navList = [{
  id: 1,
  name: '首页',
  url: '/home',
  isOpenNewWindow: false
}, {
  id: 2,
  name: '行业领域',
  url: '/industry',
  isOpenNewWindow: true,
  children: [{
    id: 11,
    name: '钢铁冶金',
    url: '/industry/main/steel',
    isOpenNewWindow: true,
    type: 'steel'
  }, {
    id: 12,
    name: '节能环保',
    url: '/industry/main/energy',
    isOpenNewWindow: true,
    type: 'energy'
  }, {
    id: 13,
    name: '智能制造',
    url: '',
    //  /industry/main/intelligent
    isOpenNewWindow: true,
    type: 'intelligent'
  }, {
    id: 14,
    name: '城市建设',
    url: '',
    //  /industry/main/urbanc 需要的时候在用
    isOpenNewWindow: true,
    type: 'urban'
  }]
}, {
  id: 3,
  name: '行业标准',
  url: '/standard',
  isOpenNewWindow: false
}, {
  id: 4,
  name: '科技平台',
  url: '/plat',
  isOpenNewWindow: false,
  children: [{
    id: 41,
    name: '国家重点实验室',
    url: '/plat/laboratory',
    isOpenNewWindow: false
  },
  // {
  //   id:42,
  //   name:'国家工程研究中心',
  //   url: '',
  //   isOpenNewWindow: false,
  // },
  // {
  //   id:43,
  //   name:'国家技术创新中心',
  //   url: '',
  //   isOpenNewWindow: false,
  // },
  {
    id: 44,
    name: '科研仪器设施共享服务平台',
    url: '/plat/instrumentFacility/instrument',
    isOpenNewWindow: false
  }]
}, {
  id: 5,
  name: '国外重大专项',
  url: '/major',
  isOpenNewWindow: true,
  children: [{
    id: 51,
    name: '欧盟煤炭钢铁研究基金',
    url: '/major',
    isOpenNewWindow: true,
    pid: 5
  }, {
    id: 52,
    name: 'COURSE50',
    url: '/course',
    isOpenNewWindow: true,
    pid: 5
  }]
}, {
  id: 6,
  name: '专家库',
  url: '/expert',
  isOpenNewWindow: false
}, {
  id: 7,
  name: '资源中心',
  url: '/resource',
  isOpenNewWindow: false
}, {
  id: 8,
  name: '研究报告',
  url: '/report',
  isOpenNewWindow: true
}];
export const industryList = [{
  id: 1,
  name: '钢铁冶金',
  en: 'Steel smelting',
  path: '/industry/main/steel'
}, {
  id: 2,
  name: '节能环保',
  en: 'Energy conservation',
  path: '/industry/main/energy'
}, {
  id: 3,
  name: '智能制造',
  en: 'Intelligent manufacturing'
}, {
  id: 4,
  name: '城市建设',
  en: 'urban construction'
}];
export const newsTabs = [{
  id: 1,
  name: '政策'
}, {
  id: 2,
  name: '资讯'
}, {
  id: 3,
  name: '技术'
}];
export const reportTabs = [{
  id: 1,
  name: '全部领域'
}, {
  id: 2,
  name: '钢铁冶金'
}, {
  id: 3,
  name: '节能环保'
}, {
  id: 4,
  name: '智能制造'
}, {
  id: 5,
  name: '城市建设'
}];
export const enterpriseTabs = [{
  id: 1,
  name: '全部'
}, {
  id: 2,
  name: '钢铁冶金'
}, {
  id: 3,
  name: '节能环保'
}, {
  id: 4,
  name: '智能制造'
}, {
  id: 5,
  name: '城市建设'
}];
export const searchTexts = [
// {
//   id: 1,
//   name: '全部领域'
// },
{
  id: 1,
  name: '钢铁冶金',
  industryType: 1,
  type: 'steel'
}, {
  id: 2,
  name: '节能环保',
  industryType: 2,
  type: 'energy'
}, {
  id: 3,
  name: '智能制造',
  industryType: 3,
  type: 'intelligent'
}, {
  id: 4,
  name: '城市建设',
  industryType: 4,
  type: 'urban'
}];
export const selectTypeList = reactive([{
  id: 1,
  name: '政策'
}, {
  id: 2,
  name: '资讯'
}, {
  id: 3,
  name: '顶刊'
}, {
  id: 4,
  name: '专利'
}, {
  id: 5,
  name: '报告'
}, {
  id: 6,
  name: '专家'
}, {
  id: 7,
  name: '实验室'
}, {
  id: 8,
  name: '标准'
}]);

// export const selectTypeList = reactive([
// [
//   { index: 3, name: '全部' },
//   { index: 1, name: '政策' },
//   { index: 2, name: '资讯' },
//   { index: 4, name: '技术' },
//   { index: 5, name: '企业' },
//   { index: 6, name: '报告' }
// ],
// [
//   { index: 3, name: '全部' },
//   { index: 1, name: '政策' },
//   { index: 2, name: '资讯' },
//   { index: 4, name: '技术' },
//   { index: 5, name: '企业' },
//   { index: 6, name: '报告' }
// ],
// [
//   { index: 3, name: '全部' },
//   { index: 1, name: '政策' },
//   { index: 2, name: '资讯' },
//   { index: 4, name: '技术' },
//   { index: 5, name: '企业' },
//   { index: 6, name: '报告' }
// ],
// [
//   { index: 3, name: '全部' },
//   { index: 1, name: '政策' },
//   { index: 2, name: '资讯' },
//   { index: 4, name: '技术' },
//   { index: 5, name: '企业' },
//   { index: 6, name: '报告' }
// ],
// [
//   { index: 3, name: '全部' },
//   { index: 1, name: '政策' },
//   { index: 2, name: '资讯' },
//   { index: 4, name: '技术' },
//   { index: 5, name: '企业' },
//   { index: 6, name: '报告' }
// ]
// [
//   { index: 3, name: '全部' },
//   { index: 1, name: '标题' },
//   { index: 2, name: '全文' }
// ],
// [
//   { index: 3, name: '全部' },
//   { index: 1, name: '政策' },
//   { index: 2, name: '资讯' },
//   { index: 4, name: '技术' },
//   { index: 5, name: '企业' },
//   { index: 6, name: '报告' }
// ],
// [
//   { index: 3, name: '全部' },
//   { index: 1, name: '标题' },
//   { index: 2, name: '全文' }
// ],
// [
//   { index: 3, name: '全部' },
//   { index: 1, name: '标题' },
//   { index: 2, name: '全文' }
// ],
// [
//   { index: 3, name: '全部' },
//   { index: 1, name: '标题' },
//   { index: 2, name: '全文' }
// ]
// ]);