import { request } from '../http';
import { clientUrl } from '../baseURL';
const api = {
  // 查询热词列表
  getInformationHotSearch(data = {}) {
    let params = {
      method: 'get',
      url: `${clientUrl}/newsWord/list`,
      data: data
    };
    return request(params);
  },
  // 新增热词列表
  addInformationHotSearch(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/newsWord/add`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取国家政策
  getAllCountryPolicyInInformation(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/newsClassify/nationalNavigationList?industryType=${industryType}`,      
    };
    return request(params);
  },
  // 获取类型
  getAllTypeInInformation(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/newsClassify/typeNavigationList?industryType=${industryType}`,      
    };
    return request(params);
  },
  // 获取时间
  getAllTimeInInformation(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/newsClassify/timeNavigationList?industryType=${industryType}`,      
    };
    return request(params);
  },
  // 获取主题热词
  getIndustryThemeHotWordsInformation(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/news/subjectHotWordList`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取内容热词
  getContentHotWordsInformation(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/news/contentHotWordList`,
      data: data,
      config: config
    };
    return request(params);
  },
  /**
   * @interface /news/getNewsTopCount
   * @methods get
   * @desc 行业资讯 顶部数量统计
   * @param
   */
  getTopNumber(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/news/getNewsTopCount?industryType=${industryType}`
    };
    return request(params);
  },
  /**
   * @interface /policy/getPolicyPageList
   * @methods get
   * @desc 首页 行业政策列表分页
   * @param pageSize、pageNum
   */
  getIndustryInformationPageList(data) {
    let params = {
      method: 'post',
      url: `${clientUrl}/news/queryNewsByConList?pageSize=${data.pageSize}&pageNum=${data.pageNum}&industryType=${data.industryType}`,
      data: data
    };
    return request(params);
  },
  /**
   * @interface /policy/elastic/
   * @methods get
   * @desc 行业政策详情
   * @param id
   */
  getPolicyDetailInfo(data) {
    let params = {
      method: 'get',
      url: `${clientUrl}/policy/elastic/${data.id}?industryType=${data.industryType}`
    };
    return request(params);
  },
  /**
   * @interface /news/id
   * @methods post
   * @desc 行业资讯详情
   * @param id
   */
  getInformationDetailInfo(data) {
    let params = {
      method: 'get',
      url: `${clientUrl}/news/${data.id}?industryType=${data.industryType}`
    };
    return request(params);
  },
  /**
   * @interface /collect/add
   * @methods post
   * @desc 行业资讯 收藏
   * @param id
   */
  collectArticle(data) {
    let params = {
      method: 'post',
      url: `${clientUrl}/collect/add`,
      data
    };
    return request(params);
  },
  /**
   * @interface /collect/remove/collect/{collectIds}
   * @methods post
   * @desc  取消收藏
   * @param id
   */
  cancleCollectArticle(data) {
    let params = {
      method: 'delete',
      url: `${clientUrl}/collect/remove/collect`,
      data
    };
    return request(params);
  }
};
export default api;
