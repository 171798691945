import { defineStore } from 'pinia';

// useStore 可以是 useUser、useCart 之类的任何东西
// 第一个参数是应用程序中 store 的唯一 id
export const useHomeStore = defineStore('home', {
  state: () => ({
    counter: 1,
    currentHomeIndex: null, //当前点击外层菜单的ID
    currentHomeUrl: '', //用于激活当前外层导航
    currentFerrousMetallurgyUrl: '' //用于激活当前钢铁冶金导航
  }),
  getters: {
    doubleCount: (state) => {
      return (value) => state.counter * value * 2;
    }
  },
  actions: {
    increment() {
      this.counter++;
    }
  }
});
