import { request } from '../http';
import { clientUrl } from '../baseURL';
const api = {
  // -------------------------------行业政策
  // 查询热词列表
  getPolicyHotSearch(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/policyWord/list`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 新增热词列表
  addPolicyHotSearch(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/policyWord/add`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取国家政策
  getAllCountryPolicyInPolicy(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/policyClassify/nationalPolicyList?industryType=${industryType}`,     
    };
    return request(params);
  },
  // 获取地方政策
  getAllAreaPolicyInPolicy(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/policyClassify/localPolicyList?industryType=${industryType}`,      
    };
    return request(params);
  },
  // 获取行业协会
  getAllIndustryAssociationsInPolicy(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/policyClassify/industryGuildList?industryType=${industryType}`,    
    };
    return request(params);
  },
  // 获取技术领域
  getAllIndustrytechnologyPolicy(industryType,businessType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/info/technologyNavigationList?industryType=${industryType}&businessType=${businessType}`,    
    };
    return request(params);
  },

  // 获取时间
  getAllTimeInPolicy(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/policyClassify/annualStatisticsList?industryType=${industryType}`,   
    };
    return request(params);
  },
  // 获取主题热词
  getIndustryThemeHotWordsInPolicy(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/policy/subjectHotWordList`,
      data: data,
      config: config      
    };
    return request(params);
  },
  // 获取内容热词
  getContentHotWordsInPolicy(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/policy/contentHotWordList`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取政策情感
  getEmotionInPolicy(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/policy/getEmotion`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取政策地图
  getPolicyMap(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/policy/plat`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取时间序列
  getPolicyTimeSeries(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/policy/timeSeries`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取政策列表顶部数量统计
  getPolicyTopCount(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/policy/getPolicyTopCount?industryType=${industryType}`,      
    };
    return request(params);
  },
  // 获取政策列表
  getPolicyPageList(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/policy/getPolicyPageList?pageSize=${data.pageSize}&pageNum=${data.pageNum}&industryType=${data.industryType}`,
      data: data,
      config: config
    };
    return request(params);
  }
};
export default api;
