import { request } from '../http';
import { clientUrl } from '../baseURL';
const api = {
  // -------------------------------行业政策
  // 查询热词列表
  getTechnologyHotSearch(data = {}, config) {
    let params = {
      method: 'get',
      url: `${clientUrl}/paperWord/list`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 新增热词列表
  addTechnologyHotSearch(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/paperWord/add`,
      data: data,
      config: config
    };
    return request(params);
  },
  // 获取文献类型列表
  getAllDocumentTypeInTechnology(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/paperClassify/documentTypeList?industryType=${industryType}`,      
    };
    return request(params);
  },
  // 获取发布时间导航列表
  getAllReleaseTimeInTechnology(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/paperClassify/releaseTimeList?industryType=${industryType}`,      
    };
    return request(params);
  },
  // 获取作者机构导航列表
  getAllAuthorshipInTechnology(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/paperClassify/authorshipList?industryType=${industryType}`,      
    };
    return request(params);
  },
  // 获取技术领域导航列表
  getAllTechnicalFieldInTechnology(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/paperClassify/technicalFieldList?industryType=${industryType}`,      
    };
    return request(params);
  },
  // 获取政策列表顶部数量统计
  getTechnologyTopCount(industryType) {
    let params = {
      method: 'get',
      url: `${clientUrl}/paper/getPaperTopCount?industryType=${industryType}`,      
    };
    return request(params);
  },
  // 获取前沿技术列表
  getTechnologyPageList(data = {}, config) {
    let params = {
      method: 'post',
      url: `${clientUrl}/paper/getPaperPageList?pageSize=${data.pageSize}&pageNum=${data.pageNum}&industryType=${data.industryType}`,
      data: data,
      config: config
    };
    return request(params);
  },
  /**
   * @interface /paper/chart/timeSeries
   * @methods post
   * @desc 前沿技术 时间序列
   * @param
   */
  getTechnologyTimeEcharts(data) {
    let params = {
      method: 'post',
      url: `${clientUrl}/paper/chart/timeSeries`,
      data: data
    };
    return request(params);
  },
  /**
   * @interface /paper/chart/authorNetwork
   * @methods post
   * @desc 前沿技术 合著网络
   * @param
   */
  getTechnologyNetWorkEcharts(data) {
    let params = {
      method: 'post',
      url: `${clientUrl}/paper/chart/authorNetwork`,
      data: data
    };
    return request(params);
  },
  /**
   * @interface /paper/chart/researchHotspots
   * @methods get
   * @desc 前沿技术 研究热点
   * @param
   */
  getTechnologyHotEcharts(data) {
    let params = {
      method: 'post',
      url: `${clientUrl}/paper/chart/researchHotspots`,
      data: data
    };
    return request(params);
  },
  /**
   * @interface /paper/char/allInstituteSort
   * @methods get
   * @desc 前沿技术 研究热点
   * @param
   */
  getTechnologyTopAuthorList() {
    let params = {
      method: 'get',
      url: `${clientUrl}/paper/char/allInstituteSort`
    };
    return request(params);
  },
  /**
   * @interface /paper/char/allJournalNameSecondSort
   * @methods get
   * @desc 前沿技术 研究热点
   * @param
   */
  getTechnologyTopAuthorSelectList() {
    let params = {
      method: 'get',
      url: `${clientUrl}/paper/char/allJournalNameSecondSort`
    };
    return request(params);
  },
  /**
   * @interface /paper/chart/fundedJournalNameSecond
   * @methods post
   * @desc 前沿技术 基金资助 技术领域
   * @param
   */
  getTechnologyFieldFundEcharts(data) {
    let params = {
      method: 'post',
      url: `${clientUrl}/paper/chart/fundedJournalNameSecond`,
      data
    };
    return request(params);
  },

  /**
   * @interface /paper/chart/fundedJournalNameSecond
   * @methods post
   * @desc 前沿技术 基金资助
   * @param
   */
  getTechnologyMoneyEcharts(data) {
    let params = {
      method: 'post',
      url: `${clientUrl}/paper/chart/fundsStatistics`,
      data
    };
    return request(params);
  },

  /**
   * @interface /paper/chart/fundedInstitute
   * @methods post
   * @desc 前沿技术 基金资助 作者机构图表
   * @param
   */
  getTechnologyAuthorFundEcharts(data) {
    let params = {
      method: 'post',
      url: `${clientUrl}/paper/chart/fundedInstitute`,
      data
    };
    return request(params);
  }
};
export default api;
